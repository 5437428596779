import React from 'react'
import { ActivityCascad } from '../../cascad-screens/ActivityCascad'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'
import { ActivityDescription } from '../ActivityDescription/ActivityDescription'
import classes from './ActivityPage.module.css'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import { getDate } from '../../utils/getDate'

const isDateShortFormat = false

export const ActivityPage = ({ data, EntityPage }) => {
  return (
    <CategoriesLayout heading={data?.name} categoryId={data?.category}>
      {data ? (
        <div className={classes.container}>
          <span className={classes.date}>
            {data?.broadcastDate
              ? getDate(data.broadcastDate)
              : getDate(data?.dateTime, isDateShortFormat)}
          </span>
          <ActivityDescription event={data} />
          {data.url && <VideoPlayer link={data.url} id={data.id} />}
        </div>
      ) : (
        <ActivityCascad />
      )}
      {/* #43115 | Мероприятия и Лекции - убираем QR код
      {(typeof window === 'undefined' || width >= MOBILE_WIDTH) && (
        <div className={classes.qr_block}>
          <label className={classes.ad}>
            Талантлив? Амбициозен? Сканируй QR-код!
          </label>
          <img src={QRCode} className={classes.qr} />
        </div>
      )} */}
      <div className={classes.other_events}>
        <EntityPage />
      </div>
    </CategoriesLayout>
  )
}
