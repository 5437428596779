import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import classes from './ActivityCascad.module.css'

export const ActivityCascad = () => (
  <div className={classes.wrapper}>
    <SkeletonTheme>
      <Skeleton className={classes.video} />
      <div className={classes.info}>
        <Skeleton className={classes.title} height={30} width={160} />
        <Skeleton className={classes.time} height={30} width={120} />
        <Skeleton className={classes.descirption} height={210} />
      </div>
    </SkeletonTheme>
  </div>
)
