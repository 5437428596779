import request from '@/services/request'

const url = process.env.GATSBY_APP_TARGET

export const postActivity = async (id, data) => {
  const res = await request({
    url: `${url}/api/events/add-time`,
    method: 'post',
    data,
  })
  return res
}
