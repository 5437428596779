import React, { useEffect, useRef } from 'react'
import classes from './ActivityDescription.module.css'

export const ActivityDescription = ({ event }) => {
  const ref = useRef()

  useEffect(() => {
    if (event?.description) ref.current.innerHTML = event.description
  }, [event])
  return (
    <div className={classes.wrapper}>
      <div className={classes.activity__inner}>
        {/* TODO: Возможно понадобится */}
        {/* {event.dateTime && (
          <label className={classes.date}>{getDate(event.dateTime)}</label>
        )} */}
        <label ref={ref} className={classes.description}></label>
        {event?.link && (
          <a
            target='_blank'
            className={classes.registration__btn}
            href={event.link}
          >
            Зарегистрироваться
          </a>
        )}
      </div>
    </div>
  )
}
