import React from 'react'
import { postActivity } from '../../api/points'
import { Iframe } from '../Iframe'
import classes from './VideoPlayer.module.css'

const VideoPlayer = ({ link, id }) => {
  const onVideoClick = async () => {
    const params = {
      type: 'event',
      key: id,
    }
    try {
      await postActivity(id, params)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={classes.player__section}>
      {link ? (
        <Iframe
          src={link}
          onInferredClick={onVideoClick}
          className={classes.video}
          frameBorder='0'
          allowFullScreen='allowfullscreen'
          mozallowfullscreen='mozallowfullscreen'
          msallowfullscreen='msallowfullscreen'
          oallowfullscreen='oallowfullscreen'
          webkitallowfullscreen='webkitallowfullscreen'
        />
      ) : (
        <div className={classes.no_video__wrapper}>
          <span className={classes.no_video__message}>
            В данный момент активность недоступна
          </span>
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
