import React from 'react'
import Layout from '@/layouts/MainLayout'
import { ActivityPage } from '../components/ActivityPage/ActivityPage'
import SEO from '@/components/seo'
import { navigate } from 'gatsby'
import { useQuery } from 'react-query'
import { getMediaLecture } from '../api'
import { LecturePage } from '../components'

const LectureActivity = ({ params }) => {
  const { data: lecture } = useQuery(
    ['lecture', params],
    async () => {
      const res = await getMediaLecture({ type: 'lecture' }, params['*'])
      return res.data
    },
    {
      onError: () => {
        navigate('/404')
      },
    }
  )

  return (
    <Layout>
      <SEO title={'Лекция'} />
      <ActivityPage data={lecture} EntityPage={LecturePage} />
    </Layout>
  )
}

export default LectureActivity
